import React from "react";

export const CityMap = ({ section }) => {

	let fullUrl = `${DSSConfig.urlBase}Mein-Graetzl/`;
	let frameUrl = `${fullUrl}?framed=true`;

	return (
		<section className="wm-section">
			<div className="wm-section__content">
				<h2 id={section.anchor} className="wm-anchor" style={{ cursor: "pointer" }}>
					{section.title}
				</h2>
				<div style={{ border: "1px solid #eee", borderRadius: "4px" }}>
					<iframe
						title={section.title}
						className="city-frame"
						id="city-frame"
						scrolling="no"
						height="500"
						src={frameUrl}
					></iframe>
				</div>
				<p
					className="wm-u-tar js-wm-block-link"
					data-block-link-ready="wm-feed__item--block"
				>
					<a href={fullUrl} className="wm-link--unstyled">
						Vollansicht
					</a>
				</p>
			</div>
		</section>
	);
};
