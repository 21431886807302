﻿import React from "react";

import { Linkbar } from "../patterns/dashboard/Linkbar";
import { useDashboardData } from "./useDashboardData";

const DashboardEntry = () => {
	const { sections, logged } = useDashboardData();


	return (
		<>
			<div className="wm-stage">
				<div className="wm-stage__content">
					<div className="wm-stage__text">
						<h1 className="wm-stage__heading">
							{logged ? "Mein Wien" : "Mein Stadt Wien Konto"}
						</h1>
					</div>
				</div>
			</div>
			{logged && <Linkbar sections={sections} />}
			{sections.map(
				(S) => S.visible && <S.Component key={S.anchor} section={S} />
			)}
		</>
	);
};

export default DashboardEntry;
