import { useEffect, useState } from "react";
import axios from "axios";

import feedStaticData from "./feedStaticData.json";

import { Teaser } from "../patterns/dashboard/Teaser";

import { Activities } from "../patterns/dashboard/Activities";
import { Termins } from "../patterns/dashboard/Termins";
import { CityMap } from "../patterns/dashboard/CityMap";
import { HereNow } from "../patterns/dashboard/HereNow";
import { FeedCard } from "../patterns/dashboard/FeedCard";

import Carousel from "../patterns/dashboard/Carousel";

export const anchor = (name) => name.replace(/[\s&?@#]*/g, "");

const loggedIn = () => DSSData.login;

const feedUrl = () => {
    let url = DSSConfig.urlBase + 'api/AppCms/Feed';

    let loc = DSSData.location;
    let ps = '';

    if (loc?.home) {
        ps += `&home_latitude=${loc.home[1]}`;
        ps += `&home_longitude=${loc.home[0]}`;
    }

    if (loc?.current) {
        ps += `&current_latitude=${loc.current[1]}`;
        ps += `&current_longitude=${loc.current[0]}`;
    }

    if (ps) url = `${url}?${ps.slice(1)}`;

    return url;
}

const departureUrls = () => {
    let url = DSSConfig.urlBase + 'api/AppCms/Departure';

    return DSSData.wienAdresse
        .map(db => db.inhalt)
        .filter(a => a.coordinates?.length === 2)
        .map(a => `${url}?lng=${a.coordinates[0]}&lat=${a.coordinates[1]}&adr=${a.address}`);
}

const FeedCarousel = ({ section }) => {
    return (
        <Carousel
            meta={{
                title: { text: section.title, url: section.anchor },
                link: section.data.fixedId === "MEINE AMTSWEGE" && {
                    text: "Alle Amtswege",
                    url: "/Meine-Amtswege"
                }
            }}
        >
            {section.data.items.map((card, idx) => (
                <FeedCard card={card} key={"card" + idx} />
            ))}
        </Carousel>
    );
};

export const CarouselSection = (section) =>
    Object({
        title: section.name,
        anchor: anchor(section.fixedId.replace("MEINE", "")),
        data: section,
        visible: section?.items.length > 0,
        Component: FeedCarousel
    });

export const Now = (section) =>
    Object({
        title: section.name,
        anchor: anchor(section.fixedId),
        data: section,
        visible: section?.items.length > 0,
        Component: HereNow,
        departures: window.dashboardData?.departures ?? []
    });

export const Map = () =>
    Object({
        title: "Grätzl",
        anchor: "GRAETZL",
        data: [],
        visible: true,
        Component: CityMap
    });

export const BrokerSections = () => {
    let termins = window.dashboardData?.termins || [];
    let activities = window.dashboardData?.activities?.data || [];

    return [
        {
            title: "Aktivitäten",
            anchor: "AKTIVITAETEN",
            data: activities,
            visible: activities.length > 0,
            Component: Activities
        },
        {
            title: "Termine",
            anchor: "TERMINE",
            data: termins,
            visible: termins.length > 0,
            Component: Termins
        }
    ];
};

export const StaticSections = () => {
    return [
        {
            title: "",
            anchor: "teaser",
            data: [],
            visible: true,
            Component: Teaser
        },
        CarouselSection(feedStaticData[0]),
        Map()
    ];
};

export const getSections = () => {
    let feed = window.dashboardData.feed;

    if (!loggedIn()) return StaticSections();

    let brokers = BrokerSections();
    let map = Map();

    if (!feed.length) return [map, ...brokers];

    let data = [];

    feed.forEach((section) => {
        switch (section.fixedId) {
            case "JETZT":
                data.push(Now(section));
                break;

            case "GRAETZL":
                data.push(map);
                break;

            case "MEINE_AKTIVITÄTEN":
                data.push(brokers[0]);
                break;

            case "MEINE_TERMINE":
                data.push(brokers[1]);
                break;

            case "MEINE_GRUPPENAKTIVITAETEN":
                break;

            default:
                data.push(CarouselSection(section));
                break;
        }
    });

    return data;
};

export const useDashboardData = () => {
    const [sections, setSections] = useState([]);

    const logged = loggedIn();

    useEffect(() => {

        let update = (log) => {
            console.log(log)
            setSections(getSections());
            loadPatternLibScripts(200);
        }

        if (!logged)
        {
            update("Not Logged.");
            return;
        }

        let data = window.dashboardData;

        axios(feedUrl())
            .then((r) => {
                data.feed = r.data;

                let deps = departureUrls().map(
                    (url) => axios(url)
                        .then((r) => data.departures.push(r.data))
                        .catch((e) => console.log(e.message))
                );

                Promise.allSettled(deps).then(update);
            })
            .catch(update)
    }, []);

    return { sections, logged };
};
